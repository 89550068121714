import React from 'react'
import styled from 'styled-components'
import themeColor from 'utils/themeColor'
import RemarkRenderer from '../RemarkRenderer'
import StoryblokForm from '../StoryblokForm'

// ===================================================
// STYLES
// ===================================================

const Container = styled('div')({
  width: '100%',
  background: ({ background, theme }) => themeColor(background, null, theme),
})

const Columns = styled('div')({
  maxWidth: 1600,
  margin: 'auto',
  padding: '48px 16px',
  display: 'flex',
  '@media (max-width: 960px)': {
    flexDirection: 'column',
  },
})

const LeftColumn = styled('div')({
  flex: 1,
  position: 'relative',
  marginRight: 40,
  '@media (max-width: 960px)': {
    marginRight: 0,
  },
})

const TextContainer = styled('div')({
  position: 'sticky',
  top: 48,
  color: ({ color, theme }) => themeColor(color, 'darkGray', theme),
})

const Heading = styled('div')({
  color: 'black', // TODO: why is theme.colors.black.color not black?
  fontSize: 32,
  fontWeight: 'bold',
  marginBottom: 24,
})

const TextContent = styled('div')({
  fontSize: 18,
  lineHeight: '30px',
  marginBottom: 24,
})

const Caption = styled('div')({
  fontSize: 13,
  lineHeight: '16px',
})

const RightColumn = styled('div')({
  flex: 1,
  marginLeft: 40,
  '@media (max-width: 960px)': {
    marginLeft: 0,
    marginTop: 40,
  },
})

const FormContainer = styled('div')({})

const FormFields = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  '& table': {
    width: 'auto',
    margin: '0 -8px',
  },
})

// ===================================================
// COMPONENTS
// ===================================================

const StoryblokStickyForm = ({
  data: { background, heading, textContent, caption, body: formData },
}) => (
  <Container background={background}>
    <Columns>
      <LeftColumn>
        <TextContainer>
          <Heading>{heading}</Heading>
          <TextContent>
            <RemarkRenderer ast={textContent?.childMarkdownRemark?.htmlAst} />
          </TextContent>
          <Caption>{caption}</Caption>
        </TextContainer>
      </LeftColumn>

      <RightColumn>
        <FormContainer>
          <FormFields id="anfrage">
            <StoryblokForm data={formData?.[0]} />
          </FormFields>
        </FormContainer>
      </RightColumn>
    </Columns>
  </Container>
)

export default StoryblokStickyForm
